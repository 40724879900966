import {
  Box,
  ChakraProvider,
  Grid,
  theme,
  VStack,
  Text,
  Heading,
  HStack,
  Button,
  useDisclosure
} from "@chakra-ui/react"
import * as React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { FaPlus } from "react-icons/fa"
import AddPostModal from "./AddPostModal"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import PostCard, { Post } from "./Post"

export const App = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    fetch("https://serverless-api.jvo.workers.dev/posts")
      .then(req => req.json())
      .then((posts: Post[]) => setPosts(posts))
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid p={3}>
          <header style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
            <Heading size="md">Cloudflare Hiring Posts</Heading>
            <HStack size="md" alignItems="center">
              <Button size="sm" leftIcon={<FaPlus />} onClick={onOpen}>Add Post</Button>
              <ColorModeSwitcher size="sm" />
            </HStack>
          </header>
          <VStack
            spacing={8}
            justifySelf="center"
            w={{ base: "sm", md: "xl" }}
          >
            {
              posts.map((post, idx) => <PostCard key={idx} initialPost={post} />)
            }
          </VStack>
        </Grid>
        <AddPostModal isOpen={isOpen} onClose={onClose} />
      </Box>
    </ChakraProvider>
  );
}
