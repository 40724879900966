import { Avatar, Box, Button, ButtonGroup, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import AddReactionPopover from './AddReactionPopover';

export interface Post {
  id: string;
  title: string;
  username: string;
  content: string;
  reactions: { [key: string]: number }
};

const ReactionButton: React.FC<{ postId: string, reaction: string, amount: number }> = ({ postId, reaction, amount }) => {
  const [voted, setVoted] = useState<boolean>(false);
  const [amountState, setAmountState] = useState<number>(amount);

  const onClick = async () => {
    if (!voted) {
      await fetch(`https://serverless-api.jvo.workers.dev/posts/${postId}/reactions`, {
        method: "POST",
        body: JSON.stringify({ reaction })
      })
      setVoted(true);
      setAmountState(amountState + 1);
    }
  }

  return (
    <Button
      leftIcon={<Text>{reaction}</Text>}
      variant={voted ? "solid" : "ghost"}
      onClick={onClick}
    >
      {amountState}
    </Button>
  )
};

const PostCard: React.FC<{ initialPost: Post }> = ({ initialPost }) => {
  const [post, setPost] = useState<Post>(initialPost)

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      textAlign="left"
      w="100%"
    >
      <VStack p="1rem" alignItems="flex-start" >
        <HStack width="100%" justifyContent="space-between">
          <Heading size="lg">{post.title}</Heading>
          <HStack fontSize="xs">
            <Avatar name={post.username} size="xs" />
            <Text>{post.username}</Text>
          </HStack>
        </HStack>
        <Text whiteSpace="pre-line" fontSize="sm">
          {post.content}
        </Text>
        <Divider />
        <HStack width="100%" justifyContent="space-between">
          <ButtonGroup size="xs" variant="ghost">
            {
              Object.keys(post.reactions).map((reaction) => (
                <ReactionButton
                  key={reaction}
                  postId={post.id}
                  reaction={reaction}
                  amount={post.reactions[reaction]}
                />
              ))
            }
            <AddReactionPopover
              postId={post.id}
              onAdd={(reaction) => {
                const newPost = {...post};
                newPost.reactions[reaction] = (newPost.reactions[reaction] || 0) + 1;
                setPost(newPost);
              }}
            />
          </ButtonGroup>
        </HStack>
      </VStack>
    </Box>
  );
};

export default PostCard;