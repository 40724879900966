import { ButtonGroup, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Text } from '@chakra-ui/react';
import * as React from 'react';
import { FaPlus } from 'react-icons/fa';

const AddReactionPopover: React.FC<{postId: string, onAdd: (reaction: string) => void}> = ({ postId, onAdd }) => {

  const addReaction = async (postId: string, reaction: string) => {
    await fetch(`https://serverless-api.jvo.workers.dev/posts/${postId}/reactions`, {
      method: "POST",
      body: JSON.stringify({ reaction })
    });
    onAdd(reaction);
  }

  return (
    <Popover>
      {
        ({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <IconButton aria-label="Add Reaction" icon={<FaPlus />} />
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="100%">
                <PopoverBody>
                  <ButtonGroup variant="ghost">
                    <IconButton 
                      icon={<Text>👍</Text>} 
                      aria-label="Add Reaction" 
                      onClick={async () => {
                        await addReaction(postId, "👍");
                        onClose();
                      }} 
                    />
                    <IconButton 
                      icon={<Text>❤️</Text>} 
                      aria-label="Add Reaction" 
                      onClick={async () => {
                        await addReaction(postId, "❤️");
                        onClose();
                      }} 
                    />
                    <IconButton 
                      icon={<Text>😊</Text>} 
                      aria-label="Add Reaction" 
                      onClick={async () => {
                        await addReaction(postId, "😊");
                        onClose();
                      }} 
                    />
                    <IconButton 
                      icon={<Text>🎉</Text>} 
                      aria-label="Add Reaction" 
                      onClick={async () => {
                        await addReaction(postId, "🎉");
                        onClose();
                      }} 
                    />
                  </ButtonGroup>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        )
      }
    </Popover>
  );
};

export default AddReactionPopover;