import {
  Button,
  FormControl,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  Input,
  FormLabel,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Textarea
} from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';

const AddPostModal: React.FC<{ isOpen: boolean; onClose: () => void }> = (props) => {
  const [username, setUsername] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");


  const addPost: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    await fetch("https://serverless-api.jvo.workers.dev/posts", {
      method: "POST",
      body: JSON.stringify({ username, title, content })
    });

    window.location.reload();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>Add a Post</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <form>
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input value={username} onChange={(e) => setUsername(e.target.value)}/>
            </FormControl>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input value={title} onChange={(e) => setTitle(e.target.value)}/>
            </FormControl>
            <FormControl>
              <FormLabel>Content</FormLabel>
              <Textarea value={content} onChange={(e) => setContent(e.target.value)}/>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" onClick={addPost}>
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddPostModal;